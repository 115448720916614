import React, { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import Modal from 'react-modal';
import Grid from '@mui/material/Grid2';
import i18n from '../../i18n';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Tooltip, TextField } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { palette } from '../../assets/Palette';
import { DropdownMenu } from '../Dropdown.jsx';
import ChangePasswordModal from './ChangePasswordModal';


const EditUserModal = ({user, roleRights, customerContext, onSubmit, onClose}) => {

    const editUserModalStyle = {
        content: {
            top: '17%',
            left: '50%',
            right: '72%',
            marginRight: '-50%',
            transform: 'translate(-50%, -17%)',
            overlay: { zIndex: 1000 }
        }
    };

    const [newUsername, setNewUsername] = useState(null);
    const [userRole, setUserRole] = useState(user?.name?.role ?? 'standard_read_only');
    const [password, setPassword] = useState(null);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);
    const [isUsernameError, setIsUsernameError] = useState(false)
    const [isPasswordError, setIsPasswordError] = useState(false)

    const confirmDeletion = () => {
        return(
        <MuiAlert
        onClose={() => setIsConfirmDeletePopupOpen(false)}
        severity="warning"
        color="warning"
        action={
            <>
            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setIsConfirmDeletePopupOpen(false)}
            />
            </>
        }
        >
        <h2>{i18n.t('userManagement.sureDelete')}</h2>
        <h2>{i18n.t('userManagement.delete') + ': ' + user.name}</h2>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <Button
            variant="text"
            style={{color: palette.warning.main}}
            onClick={() => {
                deleteUser(user.name);
                setIsConfirmDeletePopupOpen(false);
            }}
            >{i18n.t('userManagement.yes')}
            </Button>
            
            <Button
            variant="text"
            style={{color: palette.warning.main}}
            onClick={() => setIsConfirmDeletePopupOpen(false)}
            >
            {i18n.t('userManagement.no')}
            </Button>
        </div>
        </MuiAlert>
        )
    }

    const toggleChangePasswordModal = () => {
        setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
    }

    const handleUsernameInput = (e) => {
        setNewUsername(e.target.value)
        setIsUsernameError(!isValidUsername(e.target.value))
    }

    const handlePasswordInput = (e) => {
        setPassword(e.target.value)
        setIsPasswordError(e.target.value.trim().length === 0);
    }

    const selectUserRole = (role) => {
        setUserRole(role)
    }

    const handleDeleteClick = () => {
        setIsConfirmDeletePopupOpen(true)
    }

    const handleSubmitClick = async () => {
        let isValid = true
        if(user === null){
            if(newUsername === null || newUsername.trim().length === 0){
                setIsUsernameError(true);
                isValid = false
            }
            if(password === null || password.trim().length === 0){
                setIsPasswordError(true);
                isValid = false
            }
            if(isValid){
                isValid = await createUser()
            }
        } else {
            if(newUsername !== null && newUsername.trim().length === 0){
                isValid = false
                setIsUsernameError(true);
            }
            if(isValid){
                isValid = await updateUser()
            }
        }
        if(isValid){
            setNewUsername(null);
            setPassword(null);
            onSubmit();
        }
    }

    const createUser = async () => {
        const data = {
            'username': newUsername,
            'password': password,
            'userRole': userRole
        }
        try {
            const res = await customerContext.userManagementApiService.createUser(data);
            if(res.status === 409){
                alert(i18n.t('userManagement.userAlreadyExists'))
                setIsUsernameError(true)
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
        setPassword(null);
        return true;
    }

    const updateUser = async () => {
        let data = {OldUsername: user.name, username: user.name}
        if(Boolean(newUsername) && (newUsername !== user.name)) data = {...data, username: newUsername}
        if(userRole !== user.role) data = {...data, userRole: userRole}
        if(JSON.stringify(data) !== JSON.stringify({OldUsername: user.name, username: user.name})){
            try {
                const res = await customerContext.userManagementApiService.updateUser(data);
                if(res.status === 409){
                    alert(i18n.t('userManagement.userAlreadyExists'))
                    setIsUsernameError(true)
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        }
        return true;
    }

    const deleteUser = async () => {
        try {
            const res = await customerContext.userManagementApiService.deleteUser(user.name);
            setIsConfirmDeletePopupOpen(false)
        } catch (error) {
            console.error(error);
        }
        onClose()
    }

    const isPasswordChangable = () => {
        return (Boolean(user?.name) && newUsername === null || (newUsername === user?.name)) || (!Boolean(user?.name) && isValidUsername(newUsername))
    }

    const isValidUsername = (username) => {
        if (username === null) return false;
                
        const isEmpty = username => !username || username.trim().length === 0;
        const regex = /^[A-Za-z0-9_-]+$/;
        const length = username.length;
        
        if (isEmpty(username)) return false; 
        if (length < 3 || length > 20) return false;
        if (username.trim() !== username || username.trim().length !== length) return false;
        if (!regex.test(username)) return false;
        return true;
    }
    
    return(
        <Modal
        isOpen={true}
        style={editUserModalStyle}
        ariaHideApp={false}
        >
        {isChangePasswordModalOpen && <ChangePasswordModal toggleChangePassword={toggleChangePasswordModal} toggleChangePasswordModal={isChangePasswordModalOpen} username={user.name} isUserManagement={true}/>}
        {isConfirmDeletePopupOpen && confirmDeletion()}
        <Grid>
        <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', top: '5%' }}>
            <Tooltip title={"close"}>
                <IconButton
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => { onClose()}}
                    style={{ outline: 'none' }}
                >
                    <CloseRoundedIcon  style={{ color: palette.warning.close }}/>
                </IconButton>
            </Tooltip>
        </div>
        {user ? <h2 style={{color: palette.text.dark}}>{i18n.t('userManagement.edit') + ' ' + user.name}</h2> : 
        <h2 style={{color: palette.text.dark}}>{i18n.t('userManagement.createNew')}</h2>}
        </Grid>
        <Grid>
        <TableContainer>
          <Table className="edit_user_table">
            <TableHead>
              <TableRow>
                <TableCell style={{color: palette.text.ui_text}}>{i18n.t('userManagement.name')}</TableCell>
                <TableCell style={{color: palette.text.ui_text, padding: "24px"}}><TextField id="standard-basic" 
                InputProps={{
                    disableUnderline: Boolean(user) && !isUsernameError,
                    style: { color: palette.text.dark, borderColor: palette.secondary.light }
                  }}
                error={isUsernameError}
                placeholder={isUsernameError ? i18n.t('userManagement.usernameRequired') : ''}
                defaultValue={user ? user.name : ''} 
                onChange={handleUsernameInput}
                ></TextField></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell style={{color: palette.text.ui_text}}>{i18n.t('userManagement.password')}</TableCell>
                {user ?
                <TableCell>          
                    <Button
                    variant="text"
                    disabled={!isPasswordChangable()} 
                    style={{color: palette.text.dark, outline: 'none'}}
                    onClick={() => { toggleChangePasswordModal() }}
                >{i18n.t('changePasswordModal.changePassword')}</Button>
                </TableCell> : <TableCell style={{color: palette.text.ui_text, padding: "24px"}}>
                    <TextField 
                        id="standard-basic" 
                        onChange={handlePasswordInput}
                        error={isPasswordError}
                        placeholder={isPasswordError ? i18n.t('userManagement.passwordRequired') : ''}
                    /></TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell style={{color: palette.text.ui_text}}>{i18n.t('userManagement.role')}</TableCell>
                <TableCell><DropdownMenu 
                    style={{color: palette.text.dark}}
                    listProp={Object.keys(roleRights)} 
                    defaultValue={user ? i18n.t(user.role) : i18n.t(userRole)} 
                    translationKey={'userManagement'}
                    onSelect={selectUserRole}/>
                </TableCell>
            </TableRow>
            <TableRow>
            <TableCell style={{color: palette.text.ui_text}}>{i18n.t('userManagement.rights')}</TableCell>
                <TableCell>
                <Table className="users_rights_table">
                <TableBody>
                {roleRights[userRole].map(obj => (
                    <TableRow key={obj.right}>
                <Tooltip title={obj.description}><TableCell style={{ border: 'none', color: palette.text.ui_text }}>{i18n.t('userManagement.'+obj.right)}</TableCell></Tooltip>
                </TableRow>
                ))}
                </TableBody>
                </Table>
                </TableCell></TableRow>
            </TableBody>
            </Table>
        </TableContainer>
        </Grid>
        <br/>
        <Grid   style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '2%',
                marginRight: '2%'
            }}>
            <Button variant="contained"
                onClick={ () => handleSubmitClick()}
                style={{color: palette.text.veryLight, backgroundColor: palette.primary.main, outline: 'none'}}
            >
                {i18n.t('userManagement.submit')}
            </Button>
            {user &&
            <Button variant="contained"
                style={{color: palette.text.veryLight, backgroundColor: palette.error.main, outline: 'none'}}
                onClick={ () => handleDeleteClick()}
            >
                {i18n.t('userManagement.delete')}
            </Button>
            }
        </Grid>
        </Modal>
    )
}

export default EditUserModal;