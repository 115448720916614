import APIService from './base/APIService.js';
import { getBrickDescription, getBrickCode } from '../../../common/helpers/Common.js';

export default class MigrosAPIService extends APIService {

    API_PATH = process.env.REACT_APP_MIGROS_URL;
    DEFAULT_TARGET_MARKET = '276';

    getProductDetails(xml, origin) {
        return this.post('get-product-details/', JSON.stringify({ xmlContent: xml, origin: origin }));
    }

    getGPCData(gpcCategoryCode) {
        return this.post('get-gpc-data/', JSON.stringify({ gpcCode: gpcCategoryCode }));
    }

    getCompleteLangGPCData() {
        return this.getWithUIToken('get-all-gpc-data/');
    }

    downloadOriginalMessage(productId, source) {
        return this.postWithUIToken('download-original-message/', JSON.stringify({id: productId, source: source}))
    }

    getTargetMarkets() {
        let query = {
            aggs: {
                targetMarkets: {
                    terms: {
                        field: "targetMarket",
                        size: 10000
                    }
                }
            }
        }
        return this.post('get-target-markets/', JSON.stringify(query));
    }

    getDataCount() {
        return this.get('get-sources/');
    }

    getDashboardData() {
        return this.get('get-dashboard-data/');
    }

    search(keyword, size, offset, logicalOperator, originSwitches, isConsumerUnit, isBaseUnit, exactSearch, targetMarketSwitches, searchQueryFilter) {
        let keywords = keyword.split(' ')
        let negationKeywords = []
        let filterAttributesList = []
        let searchQuery = ''
        let originQuery = ''
        let searchQueryFieldList = []
        let targetMarketQuery = ''
 
        for (let i = 0; i < originSwitches.length; i++) {
            for (let key in originSwitches[i]) {
                if (originSwitches[i][key] === true) {
                    originQuery = originQuery + ' OR ' + key
                }
            }
        }
        originQuery = originQuery.substring(4)
        for (let i = 0; i < keywords.length; i++) {
            if (keywords[i][0] === '-') {
                if (keywords[i].length > 1) {
                    negationKeywords.push(keywords[i])
                }
            }
            else {
                if (keywords[i].length > 0) {
                    if (keywords[i].includes(':')) {
                        if (keywords[i].split(':')[1].length > 0) {
                            let filterAttributePair = keywords[i].split(':')
                            let filterCondition = {}
                            let attribute = filterAttributePair[1]
                            filterCondition['query_string'] = {}
                            filterCondition['query_string']['fields'] = [filterAttributePair[0]]
                            if (exactSearch) {
                                filterCondition['query_string']['query'] = '(' + attribute + ')'
                                searchQuery = searchQuery + ' (' + filterAttributePair[1] + ') ' + logicalOperator
                            } else {
                                filterCondition['query_string']['query'] = '(*' + attribute + '*)'
                                searchQuery = searchQuery + ' (*' + filterAttributePair[1] + '*) ' + logicalOperator
                            }
                            filterAttributesList.push(filterCondition)
                        }
                    } 
                    else {
                        if (exactSearch) {
                            searchQuery = searchQuery + ' (' + keywords[i] + ') ' + logicalOperator
                        } else {
                            searchQuery = searchQuery + ' (*' + keywords[i] + '*) ' + logicalOperator
                        }
                    }
                }
            }
        }
        if (logicalOperator === 'AND') {
            searchQuery = searchQuery.slice(0, -4)
        } else if (logicalOperator === 'OR') {
            searchQuery = searchQuery.slice(0, -3)
        }
        for (let i = 0; i < negationKeywords.length; i++) {
            searchQuery = searchQuery + ' ' + negationKeywords[i]
        }
        for (let i = 0; i < targetMarketSwitches.length; i++) {
            for (let key in targetMarketSwitches[i]) {
                if (targetMarketSwitches[i][key] === true) {
                    targetMarketQuery = targetMarketQuery + ' OR ' + key
                }
            }
        }
        targetMarketQuery = targetMarketQuery.substring(4)
        switch (searchQueryFilter) {
            case 'all':
                searchQueryFieldList.push('gtin', 'manufacturer', 'gpcBrick', 'brandName', 'content')
                break;
            case 'gtin':
                    searchQueryFieldList = ['gtin']
                    break;
            case "manufacturer":
                searchQueryFieldList = ['manufacturer']
                break;
            case "gpc":
                searchQueryFieldList = ['gpcBrick']
                break;
            case "tii":
                searchQueryFieldList = ['content']
                break;
            case "brand":
                searchQueryFieldList = ['brandName']
                break;
            default:
                console.log('inside switch: incorrect filter: ' + searchQueryFilter);
                break;
        }
        let isConsumerUnitOnly_query = {
                        'query_string': {
                             'query': isConsumerUnit,
                             'fields': ['isConsumerUnit']
                        }
                }
        let isBaseUnitOnly_query = {
            'query_string': {
                'query': 'BASE_UNIT_OR_EACH OR BASE UNIT', // 'BASE UNIT' for trustbox and datanature
                'fields': ['unitDescriptor']
           }
        }
        let query = {
                query: {
                    bool: {
                        must: [
                            {
                                "query_string": {
                                    "query": originQuery,
                                    "fields": [
                                        "origin"
                                    ]
                                }
                            },
                            {
                                "query_string": {
                                    "query": targetMarketQuery,
                                    "fields": [
                                        "targetMarket"
                                    ]
                                }
                            },
                            {
                                "query_string": {
                                    "query": searchQuery,
                                    "fields": searchQueryFieldList
                                }
                            }
                        ]
                    }
                },
                from: offset,
                size: size
            }
        
        if(isConsumerUnit){
            query['query']['bool']['must'].push(isConsumerUnitOnly_query)
        }
        if(isBaseUnit){
            query['query']['bool']['must'].push(isBaseUnitOnly_query)
        }
        if (filterAttributesList.length > 0) {
            let condition = ''
            if (logicalOperator === 'AND') {
                condition = 'must'
            } else if (logicalOperator === 'OR') {
                condition = 'should'
            }
            let filterObject = {}
            filterObject['bool'] = {}
            filterObject['bool'][condition] = []
            for (let i = 0; i < filterAttributesList.length; i++) {
                filterObject['bool'][condition].push(filterAttributesList[i])
            }
            query['query']['bool']['must'].push(filterObject)
        }
        return this.post('_ui_search', JSON.stringify(query));
    }

    handleGPCManufacturerFilterButtons(theFilterList, originalRecordList, completeGPCData, language) {
        const theManufacturers = theFilterList['manufacturer']
        const theGPCList = theFilterList['gpc']
        let brickDescription = ''
        let gpcBrickCode = ''

        let recordList = originalRecordList.filter(function(product) {
            gpcBrickCode = getBrickCode(product)
            brickDescription = getBrickDescription(completeGPCData,language,gpcBrickCode)
            const gpcCode = gpcBrickCode + ' - ' + brickDescription
            if( 'informationProviderOfTradeItem' in product) {
                return theManufacturers.includes(product['informationProviderOfTradeItem']) && theGPCList.includes(gpcCode)
            } else if('manufacturer' in product) {
                return theManufacturers.includes(product['manufacturer']) && theGPCList.includes(gpcCode)
            }
            return true
        })
        return { records: recordList };
    }
}