
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from "react";
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import {palette} from '../../assets/Palette.jsx';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CustomerContext } from "../../common/CustomerContext.js";
import InitList from '../plm-service/InitList.jsx';
import SyncList from '../plm-service/SyncList.jsx';

class AldiPlmServiceModal extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            valueTab: 0,
        }
    }

    a11yProps(index) {
        return {
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({valueTab: newValue})
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.props.toggleAldiPlmServiceModal}
                style={{ overlay: { zIndex: 1000 } }}
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', right: '4%', margionTop: '-15px' }}>
                    <Grid container>
                        
                        <Grid item  style={{paddingTop: "11px"}}>
                            <Tooltip title="Close"> 
                                <IconButton
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={this.props.handleAldiPlmServiceModalClose}
                                    style={{ outline: 'none',  marginRight: '8%'}}
                                    size="large">
                                    <CloseRoundedIcon style={{ color: palette.warning.close }} />
                                </IconButton>
                            </Tooltip>  
                        </Grid>
                    </Grid>
                </div>
                <div style={{paddingLeft: '16px'}}>
                    <h2 key={'h1'} id="plm-service-modal-heading">PLM Service</h2>
                    <Box sx={{ bgcolor: 'background.paper', width: "100%", letterSpacing: "0.02857em !important" }}>
                        <AppBar position="static">
                            <Tabs 
                                value={this.state.valueTab} 
                                onChange={this.handleTabChange} 
                                aria-label="PLM Service"
                                indicatorColor="secondary"
                                textColor="inherit"
                                >
                                <Tab 
                                    label="Init List"
                                    {...this.a11yProps(0)} 
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    id={"plm-init-lint-tab"}
                                />
                                <Tab 
                                    label="Sync List"
                                    {...this.a11yProps(1)} 
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                        },
                                    }}
                                    id={"plm-sync-list-tab"}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.valueTab} index={0}>
                            <InitList activeTab={this.state.valueTab} appContext={this.context} t={t}/>   
                        </TabPanel>
                        <TabPanel value={this.state.valueTab} index={1}>
                            <SyncList activeTab={this.state.valueTab} appContext={this.context} t={t}/>
                        </TabPanel>
                    </Box>
                </div>
            </Modal>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>{children}</Box>
        )}
      </div>
    );
}

export default withTranslation()(AldiPlmServiceModal);