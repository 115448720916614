
import React from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { CustomerContext } from "../../common/CustomerContext.js";
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import { isGtinValid, isGlnValid } from '../../common/helpers/Validator.js'
import RefreshIcon from '@mui/icons-material/Refresh';

class InitListSearchFilter extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            gtinSearch: this.props.gtinSearch,
            glnSearch: this.props.glnSearch,
            targetMarket: this.props.targetMarketSearch,
            purchaseGroup: this.props.purchaseGroupSearch,
            aldiPlmIdSearch: this.props.aldiPlmIdSearch,
            isSearching: this.props.isSearching,
        }
    }

    shouldSubmitButtonBeEnabled() {
        return (isGtinValid(this.state.gtinSearch)
            && isGlnValid(this.state.glnSearch))
    }

    render() {
        const { t } = this.props;

        return (
            <Grid container direction="row">
                <Grid item xs="2">
                    <TextField id="init-list-tab-gtin-search"
                        name="gtinSearch"
                        style={{width: "90%"}}
                        label={t('aldiPlmService.gtin')}
                        value={this.props.gtinSearch}
                        error={!isGtinValid(this.props.gtinSearch)}
                        helperText={isGtinValid(this.props.gtinSearch) ? '' : t('gdsnSubscriptionModal.gtinValidationText')}
                        onChange={this.props.handleOnChange} variant="outlined" size="small"
                        inputProps={{ maxLength: 14 }}
                        disabled={this.props.isSearching}
                    />
                </Grid>
                <Grid item xs="2">
                    <TextField id="init-list-tab-gln-search"
                        name="glnSearch"
                        style={{width: "90%"}}
                        label={t('aldiPlmService.gln')}
                        value={this.props.glnSearch}
                        error={!isGlnValid(this.props.glnSearch)}
                        helperText={isGlnValid(this.props.glnSearch) ? '' : t('gdsnSubscriptionModal.gpcValidationText')}
                        onChange={this.props.handleOnChange} variant="outlined" size="small"
                        disabled={this.props.isSearching}
                    />
                </Grid>
                <Grid item xs="2">
                    <TextField id="init-list-tab-purchase-group-search"
                        name="purchaseGroupSearch"
                        style={{width: "90%"}}
                        label={t('aldiPlmService.purchaseGroup')}
                        value={this.props.purchaseGroupSearch}
                        onChange={this.props.handleOnChange} variant="outlined" size="small"
                        disabled={this.props.isSearching}
                    />
                </Grid>
                <Grid item xs="2">
                    <TextField id="init-list-tab-target-market-search"
                        name="targetMarketSearch"
                        style={{width: "90%"}}
                        label={t('aldiPlmService.targetMarket')}
                        value={this.props.targetMarketSearch}
                        onChange={this.props.handleOnChange} variant="outlined" size="small"
                        disabled={this.props.isSearching}
                    />
                </Grid>
                <Grid item xs="2">
                    <TextField id="init-list-tab-plm-id-search"
                        name="aldiPlmIdSearch"
                        style={{width: "90%"}}
                        label={t('aldiPlmService.plmId')}
                        value={this.props.aldiPlmIdSearch}
                        onChange={this.props.handleOnChange} variant="outlined" size="small"
                        disabled={this.props.isSearching}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        color="primary"
                        disabled={!this.shouldSubmitButtonBeEnabled() ||this.props.isSearching}
                        onClick={this.props.submitFunction}
                        style={{ height: '40px' }}
                        id="article-pass-tab-search-button"
                    >
                        {t('gdsnSubscriptionModal.search')}
                    </Button>
                </Grid>
                <Grid item>
                    <Tooltip title={t('markantDataRequestModal.refresh')} aria-label={t('markantDataRequestModal.refresh')}>
                        <IconButton
                            onClick={this.props.submitFunction}
                            style={{marginTop: -5, marginLeft: 5}}
                            size="large"
                            id="article-pass-tab-refresh-button">    
                            <RefreshIcon color="primary" style={{cursor: "pointer"}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }
}
  
export default withTranslation()(InitListSearchFilter);