import { Navigate } from "react-router-dom";
import ProductsLayout from "../layouts/Products.jsx";
import LoginLayout from "../layouts/Login.jsx"
import HelpLayout from "../layouts/Help.jsx";
import HelpEnLayout from "../layouts/HelpEn.jsx";
import HelpEnSchwarz from "../layouts/HelpEnSchwarz.jsx";
import HelpDeSchwarz from "../layouts/HelpDeSchwarz.jsx";
import UserManagementComponent from "../layouts/UserManagement.jsx";

export const AcsRoute = () => {
    if (acsLoggedIn()) {
      return <Navigate to='/' />;
    }
    return <Navigate to='/login' />;
};
  
export const ContentProviderRoute = () => {
    if(isUserLoggedIn()) {
      return <ProductsLayout />
    } 
    return <Navigate to='/login' />
}
  
export const HelpRoute = () => {
    if(!isUserLoggedIn()) {
      return <Navigate to='/login' /> 
    }
    if (process.env.REACT_APP_SOURCE === 'schwarz') {
      return <HelpDeSchwarz />
    }
    return <HelpLayout /> 
}
  
export const HelpEnRoute = () => {
    if(!isUserLoggedIn()) {
      return <Navigate to='/login' /> 
    }
    if (process.env.REACT_APP_SOURCE === 'schwarz') {
      return <HelpEnSchwarz />
    }
    return <HelpEnLayout /> 
}
  
export const LoginRoute = () => {
    if(isUserLoggedIn()) {
      return <Navigate to={defaultPath()} />
    } 
    return  <LoginLayout />
}

export const UserManagementRoute = (customerContext) => {
  if(isUserLoggedIn()) {
    return <UserManagementComponent />
  } 
  return  <LoginLayout />
}

function isUserLoggedIn() {
    return (localStorage.getItem('user') && new Date().getTime() - localStorage.getItem('saved') < 60 * 60 * 1000); 
}
  
async function checkSession() {
    const response = await fetch('/products/session-check/', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    if (response.status === 200) {
      localStorage.setItem('user', 'sso_user');
      localStorage.setItem('saved', new Date().getTime());
      window.location.href = '/content-provider'
      return true;
    }
    else {
      localStorage.removeItem('user');
      localStorage.removeItem('saved');
      window.location.href = '/login'
      return false;
    }
}
  
function acsLoggedIn() {
    checkSession()  
    return true
}

export function defaultPath() {
    return '/content-provider';
}