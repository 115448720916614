/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { palette } from '../../assets/Palette.jsx';
import InitListSearchFilter from './InitListSearchFilter.jsx'

const InitList = ({appContext, t}) => {

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'asc' }]);
	const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [gtinSearch, setGtinSearch] = useState('');
  const [glnSearch, setGlnSearch] = useState('');
  const [targetMarketSearch, setTargetMarketSearch] = useState('');
  const [purchaseGroupSearch, setPurchaseGroupSearch] = useState('');
  const [aldiPlmIdSearch, setAldiPlmIdSearch] = useState('');
  

  useEffect(() => {
    getPlmInitList(true);
  }, [page, pageSize, sortModel]);


  const updateRowData = (data) => {
    return data.map(row => ({
      ...row,
      showDownloadButton: true, 
      showLoader: false
    }));
  };

  const handleGtinSearch = (gtin) => {
    setGtinSearch(gtin)
  }

  const handleGlnSearch = (gln) => {
    setGlnSearch(gln)
  }

  const handleTargetMarketSearch = (gln) => {
    setTargetMarketSearch(gln)
  }

  const getPlmInitListWithFilter = () => {
    setPage(0)
    getPlmInitList(true)
  }

  const handleOnChange = (event) => {
    let fieldName = event.target.name;
    let value = event.target.value;

    switch (fieldName) {
      case 'gtinSearch':
        setGtinSearch(value);
        break;
      case 'glnSearch':
        setGlnSearch(value);
        break;
      case 'purchaseGroupSearch':
        setPurchaseGroupSearch(value);
        break;
      case 'targetMarketSearch':
        setTargetMarketSearch(value);
        break;
      case 'aldiPlmIdSearch':
        setAldiPlmIdSearch(value);
        break;
      default:
        break;
    }
  }

  const getPlmInitList = async (useFilters = false) => {
		setLoading(true); 
    const sortColumn = sortModel.length ? sortModel[0].field : 'createdAt';
    const sortDirection = sortModel.length ? sortModel[0].sort : 'asc';

    try {
      let params = {
        gln: glnSearch,
        gtin: gtinSearch,
        targetMarket: targetMarketSearch,
        purchaseGroup: purchaseGroupSearch,
        aldiPlmId: aldiPlmIdSearch,
        perPage: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        pageNumber: page + 1,
      }

      const response = await appContext.apiService.getPlmInitList(useFilters, params);
      if (response.status === 200) {
        response.json().then(res => {
          const data = updateRowData(res.data);
          setRows(data);
          setRowCount(res.total);
          console.log(rows)
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
    }
  };
	
	const columns = [
    { field: 'gtin', headerName: 'GTIN', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { field: 'gln_list', headerName: 'GLN List', flex: 0.10, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'purchasing_group', 
      headerName: 'Purchasing Group', 
      flex: 0.12, 
      filterable: false,
      headerClassName: 'header-bold',
    },
    { field: 'target_market_list', headerName: 'Target Market List', flex: 0.12, filterable: false, headerClassName: 'header-bold' },
    { 
      field: 'action_type', 
      headerName: 'Action', 
      flex: 0.07, 
      filterable: false,
      headerClassName: 'header-bold',
    },
    { 
      field: 'aldi_plm_id', 
      headerName: 'PLM Id', 
      flex: 0.07, 
      filterable: false,
      headerClassName: 'header-bold',
    },
    { 
      field: 'init_processed', 
      headerName: 'Status', 
      flex: 0.07, 
      filterable: false,
      headerClassName: 'header-bold',
    },
    { 
			field: 'createdAt', 
			headerName: 'Created At', 
			flex: 0.12, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.createdAt).toLocaleString()}</span>
			)
		},
    { 
			field: 'updatedAt', 
			headerName: 'Updated At', 
			flex: 0.12, 
			filterable: false,
      headerClassName: 'header-bold',
			renderCell: (params) => (
				<span>{new Date(params.row.updatedAt).toLocaleString()}</span>
			)
		 },
  ];

  return (
    <div style={{ height: 650, width: '100%'}}>
      <InitListSearchFilter isSearching = {isSearching} 
        handleGtinSearch = {handleGtinSearch} 
        handleGlnSearch = {handleGlnSearch} 
        HandleTargetMarketSearch = {handleTargetMarketSearch} 
        gtinSearch = {gtinSearch} 
        glnSearch = {glnSearch} 
        targetMarketSearch = {targetMarketSearch} 
        purchaseGroupSearch = {purchaseGroupSearch} 
        aldiPlmIdSearch = {aldiPlmIdSearch} 
        submitFunction = {getPlmInitListWithFilter} 
        handleOnChange = {handleOnChange}
      />
      <div style={{paddingTop: "2rem"}}></div>
      <DataGrid
        disableColumnSelector
        loading={loading}
        rows={rows}
        columns={columns}
        paginationModel={{ page, pageSize }}
        pagination
        onPaginationModelChange={(newPaginationModel) => {
          setPage(newPaginationModel.page);
          setPageSize(newPaginationModel.pageSize);
        }}
        paginationMode="server"
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            width: '98% !important',
          },
          '& .MuiCircularProgress-root': {
            color: palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
      />
    </div>
  );
};

export default InitList;
